import React from "react";
import { Routes, Route } from "react-router-dom";
import * as Constants from "./constants";
import Home from "./pages/home.js";
import About from "./pages/about.js";
import Products from "./pages/products.js";
import LowerSurfacePunch from "./pages/lower-surface-punch.js";
import RubberPunch from "./pages/rubber-punch.js";
import IsostaticPunch from "./pages/isostatic-punch.js";
import TilesMoulds from "./pages/tiles-moulds.js";
import TilesMatrix from "./pages/tiles-matrix.js";
import CarbideLiners from "./pages/carbide-liners.js";
import Clients from "./pages/clients.js";
import Infrastructure from "./pages/infrastructure.js";
import GroupOfCompanies from "./pages/group-of-companies.js";
import ContactUs from "./pages/contact.js";
import Error404 from "./pages/404-error.js";
import "./css/style.css";
import "./js/common";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path={Constants.HOME_PAGE_URL} element={<Home />} />
        <Route path={Constants.INDEX_PAGE_URL} element={<Home />} />
        <Route path={Constants.LANDING_PAGE_URL} element={<Home />} />
        <Route path={Constants.ABOUT_PAGE_URL} element={<About />} />
        <Route path={Constants.PRODUCTS_PAGE_URL} element={<Products />} />
        <Route path={Constants.LOWER_SURFACE_PUNCH_PAGE_URL} element={<LowerSurfacePunch />} />
        <Route path={Constants.RUBBER_PUNCH_PAGE_URL} element={<RubberPunch />} />
        <Route path={Constants.ISOSTATIC_PUNCH_PAGE_URL} element={<IsostaticPunch />} />
        <Route path={Constants.TILES_MOULDS_PAGE_URL} element={<TilesMoulds />} />
        <Route path={Constants.TILES_MATRIX_PAGE_URL} element={<TilesMatrix />} />
        <Route path={Constants.CARBIDE_LINERS_PAGE_URL} element={<CarbideLiners />} />
        <Route path={Constants.CLIENTS_PAGE_URL} element={<Clients />} />
        <Route path={Constants.INFRASTRUCTURE_PAGE_URL} element={<Infrastructure />} />
        <Route path={Constants.GROUP_OF_COMPANIES_PAGE_URL} element={<GroupOfCompanies />} />
        <Route path={Constants.CONTACT_PAGE_URL} element={<ContactUs />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </div>
  );
}

export default App;
