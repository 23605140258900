import React, { useEffect } from "react";
import Layout from "./layout.js";
import client1 from "../images/clients/c1.png";
import client2 from "../images/clients/c2.png";
import client3 from "../images/clients/c3.png";
import client4 from "../images/clients/c4.png";
import client5 from "../images/clients/c5.png";
import client6 from "../images/clients/c6.png";
import client7 from "../images/clients/c7.png";
import client8 from "../images/clients/c8.png";
import client9 from "../images/clients/c9.png";
import client10 from "../images/clients/c10.png";
import client11 from "../images/clients/c11.png";
import client12 from "../images/clients/c12.png";
import client13 from "../images/clients/c13.png";
import client14 from "../images/clients/c14.png";
import client15 from "../images/clients/c15.png";
import client16 from "../images/clients/c16.png";
import client17 from "../images/clients/c17.png";
import client18 from "../images/clients/c18.png";
import client19 from "../images/clients/c19.png";
import client20 from "../images/clients/c20.png";
import client21 from "../images/clients/c21.png";
import client22 from "../images/clients/c22.png";
import client23 from "../images/clients/c23.png";
import client24 from "../images/clients/c24.png";
import client25 from "../images/clients/c25.png";
import client26 from "../images/clients/c26.png";
import client27 from "../images/clients/c27.png";
import client28 from "../images/clients/c28.png";
import client29 from "../images/clients/c29.png";
import client30 from "../images/clients/c30.png";
import client31 from "../images/clients/c31.png";
import client32 from "../images/clients/c32.png";
import client33 from "../images/clients/c33.png";
import client34 from "../images/clients/c34.png";
import client35 from "../images/clients/c35.png";
import client36 from "../images/clients/c36.png";
import client37 from "../images/clients/c37.png";
import client38 from "../images/clients/c38.png";
import client39 from "../images/clients/c39.png";
import client40 from "../images/clients/c40.png";
import client41 from "../images/clients/c41.png";
import client42 from "../images/clients/c42.png";

import client43 from "../images/clients/c43.png";
import client44 from "../images/clients/c44.png";
import client45 from "../images/clients/c45.jpg";
import client46 from "../images/clients/c46.jpg";
import client47 from "../images/clients/c47.png";
import client48 from "../images/clients/c48.png";
import client49 from "../images/clients/c49.jpg";
import client50 from "../images/clients/c50.jpg";
import client51 from "../images/clients/c51.png";
import client52 from "../images/clients/c52.png";
import client53 from "../images/clients/c53.png";
import client54 from "../images/clients/c54.png";
import client55 from "../images/clients/c55.jpg";
import client56 from "../images/clients/c56.png";
import client57 from "../images/clients/c57.jpg";
import client58 from "../images/clients/c58.png";
import client59 from "../images/clients/c59.jpg";
import client60 from "../images/clients/c60.png";
import client61 from "../images/clients/c61.png";
import client62 from "../images/clients/c62.png";
import client63 from "../images/clients/c63.png";
import { seo } from "../seo.js";

export default function Clients({ pageInfo }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    seo({
      title: "Clients",
    });
  }, []);

  return (
    <Layout>
      <div className="common-pageheading">
        <div className="container">
          <h1 className="heading">Clients</h1>
        </div>
      </div>

      <section className="client-page-section">
        <div className="container">
          <div className="row client-page-sec-row">
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client1}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client2}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client3}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client4}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client5}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client6}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client7}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client8}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client9}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client10}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client11}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client12}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client13}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client14}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client15}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client16}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client17}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client18}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client19}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client20}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client21}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client22}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client23}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client24}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client25}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client26}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client27}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client28}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client29}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client30}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client31}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client32}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client33}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client34}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client35}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client36}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client37}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client38}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client39}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client40}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client41}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img
                  src={client42}
                  className="img-fluid client-img"
                  alt="Client"
                ></img>
              </div>
            </div>

            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img src={client43} className="img-fluid client-img" alt="Client 43" />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img src={client44} className="img-fluid client-img" alt="Client 44" />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img src={client45} className="img-fluid client-img" alt="Client 45" />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img src={client46} className="img-fluid client-img" alt="Client 46" />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img src={client47} className="img-fluid client-img" alt="Client 47" />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img src={client48} className="img-fluid client-img" alt="Client 48" />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img src={client49} className="img-fluid client-img" alt="Client 49" />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img src={client50} className="img-fluid client-img" alt="Client 50" />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img src={client51} className="img-fluid client-img" alt="Client 51" />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img src={client52} className="img-fluid client-img" alt="Client 52" />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img src={client53} className="img-fluid client-img" alt="Client 53" />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img src={client54} className="img-fluid client-img" alt="Client 54" />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img src={client55} className="img-fluid client-img" alt="Client 55" />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img src={client56} className="img-fluid client-img" alt="Client 56" />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img src={client57} className="img-fluid client-img" alt="Client 57" />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img src={client58} className="img-fluid client-img" alt="Client 58" />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img src={client59} className="img-fluid client-img" alt="Client 59" />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img src={client60} className="img-fluid client-img" alt="Client 60" />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img src={client61} className="img-fluid client-img" alt="Client 61" />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img src={client62} className="img-fluid client-img" alt="Client 62" />
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6 client-img-col">
              <div className="img-div">
                <img src={client63} className="img-fluid client-img" alt="Client 63" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
