import React from "react";
import { useEffect } from "react";
import Layout from "./layout.js";
import aboutImage from "../images/image5.png";
import missionImage from "../images/image6.png";
import visionImage from "../images/image7.png";
import valueImage from "../images/image8.png";
import infasImage1 from "../images/main-slider-img.png";
import infasImage2 from "../images/image9.png";
import infasImage3 from "../images/image4.png";
import certificate1 from "../images/certi1.png";
import certificate2 from "../images/certi2.png";
import dummyimg from "../images/dummy-img.jpg";
import Fancybox from "../js/fancybox.js";
import { Link } from "react-router-dom";
import * as Constants from "../constants";
import { seo } from "../seo.js";

export default function About({ pageInfo }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    seo({
      title: "About",
    });
  }, []);


  return (
    <Layout>
      <div className="common-pageheading">
        <div className="container">
          <h4 className="heading">About Us</h4>
        </div>
      </div>

      <section className="about-section">
        <div className="container">
          <div className="row about-section-row">
            <div className="col-lg-6 img-col">
              <div className="img-div">
                <img src={aboutImage} alt="About" className="img-fluid"></img>
              </div>
            </div>
            <div className="col-lg-6 text-content-col d-flex align-items-center">
              <div className="text-content">
                <div className="common-heading">
                  <h1 className="line-heading">
                    <span className="line"></span> About Company
                  </h1>
                  <h4 className="heading">Pavan Dimension</h4>
                </div>
                <p className="text">
                  PAVAN DIMENSION has at its service equipment for designing and
                  producing structured Dies And Punches, for small and large
                  size tiles. We are equipped with lasers for the detection of
                  three-dimensional surfaces, CNC milling machines and presses.
                  Our exclusive L Power system allows us to create structures up
                  to 800 X 1600 MM, with an accuracy in Details And A Production
                  rate until yesterday unobtainable.
                </p>
                <p className="text">
                  Our department in Charge of Numerical Control (CNC) Engraving
                  is able to meet the great demand for this special product
                  intended for the industrial production of tiles. Hundreds of
                  samples in aluminium (CNC) or produced through L Power are
                  displayed in our show-room dedicated to the three-dimensional
                  structures.
                </p>
                <Link to={Constants.CONTACT_PAGE_URL} className="primary-btn">
                  Contact Us Now
                </Link>
              </div>
            </div>
          </div>

          <div className="our-team-sec team-section d-none">
            <div className="container">
                <div className="common-heading">
                  <h4 className="line-heading">
                    <span className="line"></span> Talents & Expertise
                  </h4>
                  <h5 className="heading">Our Team</h5>
               </div>

               <div class="row justify-content-center">
                <div class="col-lg-3 col-md-6 text-center mb-5">
                    <div class="team-section-part">
                        <img src={dummyimg} alt="Fonic" class="img-fluid new-team-img" />
                        <div class="team-title">
                        <h5>Mr. John Doe</h5>
                        <h6 class="m-0">Lorem ipsum</h6>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 text-center">
                    <div class="team-section-part">
                        <img src={dummyimg} alt="Fonic" class="img-fluid new-team-img" />
                        <div class="team-title">
                        <h5>Mr. John Doe</h5>
                        <h6 class="m-0">Lorem ipsum</h6>
                        </div>
                    </div>
                </div>                
            </div>
            </div>
          </div>

          <div className="mission-vision-cards">
            <div className="row mission-vision-cards-row">
              <div className="col-lg-4 col-md-6 col-12 card-col">
                <div className="mission-vision-card">
                  <div className="img-div">
                    <img
                      src={missionImage}
                      alt="Mission"
                      className="img-fluid mission-vision-img"
                    ></img>
                  </div>
                  <div className="text-cnt">
                    <h4 className="heading">Mission</h4>
                    <p className="text">
                      To provide best quality punches to ceramic tiles
                      manufacturers at affordable price.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12 card-col">
                <div className="mission-vision-card">
                  <div className="img-div">
                    <img
                      src={visionImage}
                      alt="Vision"
                      className="img-fluid mission-vision-img"
                    ></img>
                  </div>
                  <div className="text-cnt">
                    <h4 className="heading">Vision</h4>
                    <p className="text">
                      To become largest exporter of Tiles Mould and Tile
                      punches.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-12 card-col">
                <div className="mission-vision-card">
                  <div className="img-div">
                    <img
                      src={valueImage}
                      alt="Value"
                      className="img-fluid mission-vision-img"
                    ></img>
                  </div>
                  <div className="text-cnt">
                    <h4 className="heading">Value</h4>
                    <p className="text">
                      To use latest technology in manufacturing practices to
                      create Finished Product.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="infrastructure-sec">
        <div className="container">
          <div className="heading-sec">
            <div className="common-heading">
              <h4 className="line-heading">
                <span className="line"></span> Company Structure
              </h4>
              <h5 className="heading">Infrastructure</h5>
            </div>
            <Link
              to={Constants.INFRASTRUCTURE_PAGE_URL}
              className="view-all-btn"
            >
              View All <i className="fi fi-rr-arrow-small-up right-arrow"></i>
            </Link>
          </div>
          <div className="row infrastructure-sec-row">
            <div className="col-md-4 col-12">
              <div className="img-div">
                <img src={infasImage1} alt="Infrastructure" className="img-fluid infas-img"></img>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="img-div">
                <img src={infasImage2} alt="Infrastructure" className="img-fluid infas-img"></img>
              </div>
            </div>
            <div className="col-md-4 col-12">
              <div className="img-div">
                <img src={infasImage3} alt="Infrastructure" className="img-fluid infas-img"></img>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="certificates-sec">
        <div className="container">
          <div className="common-heading">
            <h4 className="line-heading">
              <span className="line"></span> Registration
            </h4>
            <h5 className="heading">Certificates</h5>
          </div>

          <Fancybox
            options={{
              Carousel: {
                infinite: false,
              },
            }}
          >
            <div className="certificates-div">
              <div className="img-div">
                <a data-fancybox="certificates" href={certificate1}>
                  <img
                    src={certificate1}
                    alt="Certificates"
                    className="img-fluid certificate-img"
                  ></img>
                </a>
              </div>
              <div className="img-div">
                <a data-fancybox="certificates" href={certificate2}>
                  <img
                    src={certificate2}
                    alt="Certificates"
                    className="img-fluid certificate-img"
                  ></img>
                </a>
              </div>
            </div>
          </Fancybox>
        </div>
      </section>
    </Layout>
  );
}
