import React from "react";
import { NavLink, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import * as Constants from "../../constants";
import "@flaticon/flaticon-uicons/css/all/all.css";

// Images Import
import HeaderLogo from "../../images/logo.png";

export default function Header({ settingInfo }) {
  const toggleNav = () => {
    document.body.classList.toggle("nav-slide-open");
  };

  const handleDropdownClick = (event) => {
    event.stopPropagation();
  };

  return (
    <header>
      <div className="main-navbar-wrapper" id="main-nav">
        <nav
          className="navbar twt-navbar twt-navbar-common navbar-expand-xl flex-lg-column"
          id="navMain"
        >
          <div className="nav-bottom w-100">
            <div className="container nav-bottom-content">
              <Link to={Constants.LANDING_PAGE_URL}>
                <img
                  src={HeaderLogo}
                  alt="Pavan Dimension"
                  className="img-fluid main-header-logo"
                />
              </Link>
              <button
                type="button"
                className="btn navbar-toggler border-0"
                id="slide-toggle"
                onClick={toggleNav}
              >
                <span className="navbar-dash"></span>
                <span className="navbar-dash"></span>
                <span className="navbar-dash"></span>
              </button>
              <div className="slide navbar-slide" id="slideNav">
                <ul
                  className="navbar-nav p-0 w-100 align-items-center"
                  id="elastic_parents"
                  data-targets=".nav-item"
                >
                  <li className="nav-item">
                    <NavLink
                      className={({ isActive }) => {
                        const linkClasses = ["nav-link"];
                        if (isActive) {
                          linkClasses.push("active");
                        }
                        return linkClasses.join(" ");
                      }}
                      to={Constants.LANDING_PAGE_URL}
                    >
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className={({ isActive }) => {
                        const linkClasses = ["nav-link"];
                        if (isActive) {
                          linkClasses.push("active");
                        }
                        return linkClasses.join(" ");
                      }}
                      to={Constants.ABOUT_PAGE_URL}
                    >
                      <span className="">About</span>
                    </NavLink>
                  </li>
                  <li className="nav-item dropdown">
                    <NavLink
                      to={Constants.PRODUCTS_PAGE_URL}
                      data-bs-toggle="dropdown"
                      className={({ isActive }) => {
                        const linkClasses = ["nav-link dropdown-toggle"];
                        if (isActive) {
                          linkClasses.push("active");
                        }
                        return linkClasses.join(" ");
                      }}
                      onClick={handleDropdownClick}
                    >
                      <span className="d-flex">
                        Products{" "}
                        <i className="fi fi-br-angle-down dropdown-down-icn"></i>
                      </span>
                    </NavLink>
                    <ul className="dropdown-menu">
                      <li>
                        <Link
                          className="dropdown-item"
                          to={Constants.PRODUCTS_PAGE_URL}
                        >
                          All Products
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to={Constants.LOWER_SURFACE_PUNCH_PAGE_URL}
                        >
                          Lower Surface Punch
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to={Constants.RUBBER_PUNCH_PAGE_URL}
                        >
                          Rubber Punch
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to={Constants.ISOSTATIC_PUNCH_PAGE_URL}
                        >
                          Isostatic Punch
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to={Constants.TILES_MOULDS_PAGE_URL}
                        >
                          Tiles Moulds
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to={Constants.TILES_MATRIX_PAGE_URL}
                        >
                          Tiles Matrix (MOTHER MOULD)
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="dropdown-item"
                          to={Constants.CARBIDE_LINERS_PAGE_URL}
                        >
                          Carbide Liners
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to={Constants.CLIENTS_PAGE_URL}
                      className={({ isActive }) => {
                        const linkClasses = ["nav-link"];
                        if (isActive) {
                          linkClasses.push("active");
                        }
                        return linkClasses.join(" ");
                      }}
                    >
                      <span className="">Clients</span>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className={({ isActive }) => {
                        const linkClasses = ["nav-link"];
                        if (isActive) {
                          linkClasses.push("active");
                        }
                        return linkClasses.join(" ");
                      }}
                      to={Constants.INFRASTRUCTURE_PAGE_URL}
                    >
                      Infrastructure
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className={({ isActive }) => {
                        const linkClasses = ["nav-link"];
                        if (isActive) {
                          linkClasses.push("active");
                        }
                        return linkClasses.join(" ");
                      }}
                      to={Constants.GROUP_OF_COMPANIES_PAGE_URL}
                    >
                      Group of Companies
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className={({ isActive }) => {
                        const linkClasses = ["nav-link"];
                        if (isActive) {
                          linkClasses.push("active");
                        }
                        return linkClasses.join(" ");
                      }}
                      to={Constants.CONTACT_PAGE_URL}
                    >
                      Contact Us
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}
